<template>
    <v-container grid-list-xl fluid>
        <v-layout wrap class="mb-12">
            <v-flex xs12 v-if="details">
                <v-list-item two-line>
                    <v-list-item-content>
                        <v-list-item-title class="text-h5">
                            {{ chargerName }}
                        </v-list-item-title>
                        <v-list-item-subtitle>Carregador</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="details.board" @click="$router.push({ path: `/board/${details.board.name}` });">
                    <v-list-item-icon>
                        <v-icon>local_offer</v-icon>
                    </v-list-item-icon>
                    <v-list-item-subtitle><strong>Gateway: </strong>{{ details.board.name }}</v-list-item-subtitle>
                </v-list-item>
                <v-list-item v-if="chargerData && chargerData.lastNfc">
                    <v-list-item-icon>
                        <v-icon>nfc</v-icon>
                    </v-list-item-icon>
                    <v-list-item-subtitle>
                        <strong>Último NFC: </strong>{{ chargerData.lastNfc }}
                    </v-list-item-subtitle>
                    <v-list-item-action>
                        <v-btn small style="width: 100%" color="success" class="ma-2 white--text" @click="copy(chargerData.lastNfc)" v-if="chargerData.lastNfc">
                            Copiar
                            <v-icon right dark small> content_copy </v-icon>
                        </v-btn>
                    </v-list-item-action>
                </v-list-item>
                <v-list-item v-if="chargerData && chargerData.vpn">
                    <v-list-item-icon>
                        <v-icon>vpn_lock</v-icon>
                    </v-list-item-icon>
                    <v-list-item-subtitle>
                        <v-btn small color="blue-grey" class="white--text" style="width: 100%" :href="vpnHref" target="_blank" v-if="chargerData.vpn && isAdmin">
                            <v-icon left>vpn_lock</v-icon>
                            VPN: {{ chargerData.vpn }}
                        </v-btn>
                        <strong v-else-if="chargerData.vpn">VPN: {{ chargerData.vpn }}</strong>
                        <strong v-else>Sem acesso VPN</strong>
                    </v-list-item-subtitle>
                </v-list-item>
                <v-list-item v-if="chargerData">
                    <v-list-item-subtitle>
                        <strong>Última Comunicação do gateway: </strong>{{ chargerData.keepAlive }}
                    </v-list-item-subtitle>
                </v-list-item>
                <v-list-item v-if="chargerData && chargerData.communication">
                    <v-list-item-subtitle>
                        <strong>Comunicação RS-485 gateway : </strong>{{ chargerData.communication ? "OK" : "Erro" }}
                    </v-list-item-subtitle>
                </v-list-item>
            </v-flex>

            <v-flex xs12>
                <v-progress-linear progress color="primary" :value="timerProgress" />
            </v-flex>

            <v-flex xs12 v-if="configAdvanced">
                <v-btn small v-for="index in configAdvanced.numberPlugs" :key="index" style="width: 100%" color="blue-grey" class="white--text my-1" :href="linkNowPlug(index)"
                    target="_blank">
                    <v-icon left>link</v-icon>
                    Plug {{ index }}
                </v-btn>
            </v-flex>

            <v-flex xs12 v-if="details">
                <ExpandPanelSimple title="Mais informações">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title class="text-h5">
                                {{ details.UUID }}
                            </v-list-item-title>
                            <v-list-item-subtitle>Identificação</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon>cable</v-icon>
                        </v-list-item-icon>
                        <v-list-item-subtitle><strong>Modelo: </strong>{{ details.model }}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon>calendar_today</v-icon>
                        </v-list-item-icon>
                        <v-list-item-subtitle><strong>Última modificação: </strong>{{ details.modifiedIn }}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon>calendar_today</v-icon>
                        </v-list-item-icon>
                        <v-list-item-subtitle><strong>Data Cadastro: </strong>{{ details.createdIn }}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item v-if="details.pricePolicy">
                        <v-list-item-icon>
                            <v-icon>policy</v-icon>
                        </v-list-item-icon>
                        <v-list-item-subtitle><strong>Política de cobrança: </strong>{{ details.pricePolicy }}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item v-if="details.price">
                        <v-list-item-icon>
                            <v-icon>price_check</v-icon>
                        </v-list-item-icon>
                        <v-list-item-subtitle><strong>Preço: </strong>R$ {{ details.price }}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item v-if="details.priceKwh">
                        <v-list-item-icon>
                            <v-icon>price_change</v-icon>
                        </v-list-item-icon>
                        <v-list-item-subtitle><strong>Preço por kWh: </strong>R$ {{ details.priceKwh }}</v-list-item-subtitle>
                    </v-list-item>
                </ExpandPanelSimple>
            </v-flex>

            <v-flex xs12 v-if="config && configAdvanced">
                <ExpandPanelSimple title="Comandos">

                    <v-layout wrap v-if="configAdvanced.protocol == '1.6'">
                        <v-flex xs12 class="text-right">
                            <strong>OCPP 1.6: </strong>
                            <v-btn x-small color="error" class="ma-1 white--text" @click="reset('Hard')"> Hard Reset </v-btn>
                            <v-btn x-small color="error" class="ma-1 white--text" @click="reset('Soft')"> Soft Reset </v-btn>
                            <v-btn x-small color="error" class="ma-1 white--text" @click="close()"> Fechar Socket </v-btn>
                        </v-flex>
                        <v-flex xs12 class="text-right" v-for="index in configAdvanced.numberPlugs" :key="index">
                            <strong>Plug {{ index }}: </strong>
                            <v-btn x-small color="error" class="ma-1 white--text" @click="changeAvailability('Inoperative', index)"> Plug {{ index }}: Inoperative </v-btn>
                            <v-btn x-small color="error" class="ma-1 white--text" @click="changeAvailability('Operative', index)"> Plug {{ index }}: Operative </v-btn>
                            <v-btn x-small color="error" class="ma-1 white--text" @click="unlockConnector(index)"> Plug {{ index }}: Unlock Connector </v-btn>
                            <v-btn x-small color="error" class="ma-1 white--text" @click="setProfile(index, 1, 600)"> Plug {{ index }}: Set Profile </v-btn>
                            <v-btn x-small color="error" class="ma-1 white--text" @click="sendTriggerMeterValues(index)"> Plug {{ index }}: Trigger Meter Value </v-btn>
                        </v-flex>
                    </v-layout>

                    <Command :UUID="chargerName" :IP="chargerData.vpn" :operate="configAdvanced.operate" />

                </ExpandPanelSimple>
            </v-flex>

            <v-flex xs12 v-if="config">
                <ExpandPanelSimple title="Configurações">
                    <h2 class="primary--text mt-4">Geral</h2>
                    <v-divider class="mb-12 red" style="opacity: 0.22" />

                    <CarConfiguration :data="config" @saved="close" />

                    <h2 class="primary--text mt-12">Cadastro</h2>
                    <v-divider class="mb-12 red" style="opacity: 0.22" />

                    <ChargerForm v-if="isAdmin && configAdvanced" :itemToEdit="configAdvanced" :lock="false" @save="init" controlAPI />
                </ExpandPanelSimple>
            </v-flex>
            <v-flex xs12 v-if="isAdmin && details && details.price">
                <ExpandPanelSimple title="Teste Pagamento">
                    <PaymentOptions :chargerName="chargerName" :price="details.price" plug="1" v-if="chargerName" />
                </ExpandPanelSimple>
            </v-flex>
        </v-layout>
        <ConfirmModal ref="confirm" />
    </v-container>
</template>

<script>
import ConfirmModal from "@/components/core/ConfirmModal";
import Command from "@/components/shared/Command";
import ExpandPanelSimple from "@/components/core/ExpandPanelSimple.vue";
import PaymentOptions from "@/components/shared/Payment/PaymentOptions";
import CarConfiguration from "@/components/form/CarConfiguration";
import ChargerForm from "@/components/form/Charger";
import { mapGetters } from "vuex";

export default {
    name: "ChargerView",

    components: { PaymentOptions, CarConfiguration, ExpandPanelSimple, ChargerForm, ConfirmModal, Command },

    data() {
        return {
            isAdmin: false,
            details: null,
            chargerData: null,
            chargerName: null,
            config: null,
            configAdvanced: null,
            timer: null,
            timerProgress: 100,
        };
    },

    mounted() {
        this.isAdmin = this.verifyRole("ROLE_ADMIN");
        if (this.$route.params.name) {
            this.chargerName = this.$route.params.name;
        }
        this.init();
        this.update(false);
    },

    created() {
        this.timer = setInterval(this.timerTick, 100);
    },

    computed: {
        ...mapGetters(["verifyRole"]),
        vpnHref() {
            return this.chargerData && this.chargerData.vpn ? `http://${this.chargerData.vpn}` : "";
        },
    },

    methods: {
        linkNowPlug(plug) {
            return "https://incharge.app/now/" + this.chargerName + "/" + plug;
        },
        copy(text) {
            navigator.clipboard.writeText(text);
            this.$eventHub.$emit("msgSuccess", `Código copiado!!!`);
        },
        timerTick() {
            if (this.timerProgress > 0) {
                this.timerProgress--;
            } else {
                this.update();
                this.timerProgress = 100;
            }
        },
        async init() {
            if (this.chargerName) {
                await this.$http
                    .get(`api/v3/charger/configuration/${this.chargerName}`)
                    .then((result) => {
                        this.details = result;
                    })
                    .catch(() => {
                        this.$eventHub.$emit("msgError", "Erro ao buscar informações.");
                    });
                if (this.details && this.details.id) {
                    await this.$http
                        .get(`api/v3/car/configuration/${this.details.id}`)
                        .then((result) => {
                            this.config = result;
                        })
                    if (this.isAdmin)
                        await this.$http
                            .get(`api/v2/charger/${this.details.id}`)
                            .then((result) => {
                                this.configAdvanced = result;
                            })
                }
            }
        },
        async update(continuous = true) {
            if (this.chargerName) {
                await this.$http
                    .get(`api/v3/charger/details/${this.chargerName}`, { continuous })
                    .then((result) => {
                        this.chargerData = result;
                    })
                    .catch(() => {
                        this.$eventHub.$emit("msgError", "Erro ao buscar informações.");
                    });
            }
        },
        sendCommand(url, parameters) {
            this.$http
                .postAlternativeAPI(url, parameters)
                .then((result) => {
                    this.$eventHub.$emit("msgSuccess", result.message ? result.message : "Enviado com sucesso.");
                })
                .catch((error) => {
                    this.$eventHub.$emit("msgError", error.message ? error.message : "Erro ao enviar, tente novamente.");
                });
        },
        sendTriggerMeterValues(plug) {
            this.$refs.confirm.open("Confirma?", "Deseja realmente forçar uma leitura de medição?").then((confirm) => {
                if (confirm) this.sendCommand("api/v2/ocpp/sendTriggerMeterValues", { charger: this.chargerName, plug: plug });
            });
        },
        unlockConnector(plug) {
            this.$refs.confirm.open("Confirma?", "Deseja realmente desbloquear o carregador?").then((confirm) => {
                if (confirm) this.sendCommand("api/v2/ocpp/unlockConnector", { charger: this.chargerName, plug: plug });
            });
        },
        changeAvailability(type, plug) {
            this.$refs.confirm.open("Confirma?", "Deseja realmente alterar disponibilidade do carregador?").then((confirm) => {
                if (confirm) this.sendCommand("api/v2/ocpp/changeAvailability", { charger: this.chargerName, plug: plug, type: type });
            });
        },
        reset(type) {
            this.$refs.confirm.open("Confirma?", "Deseja realmente resetar o carregador?").then((confirm) => {
                if (confirm) this.sendCommand("api/v2/ocpp/reset", { charger: this.chargerName, type: type });
            });
        },
        close() {
            this.$refs.confirm.open("Confirma?", "Deseja realmente fechar o socket? Isso vai forçar a reconexão com novas configurações.").then((confirm) => {
                if (confirm) this.sendCommand("api/v2/ocpp/close", { charger: this.chargerName });
            });
        },
        setProfile(plug, power, time) {
            this.$refs.confirm.open("Confirma?", "Deseja realmente alterar profile do carregador?").then((confirm) => {
                if (confirm) this.sendCommand("api/v2/ocpp/setChargingProfile", { charger: this.chargerName, plug: plug, power: power, time: time });
            });
        },
    },

    beforeDestroy() {
        clearInterval(this.timer);
    },
};
</script>
